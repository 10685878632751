// Library imports
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Component imports
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

// Style imports
import Styles404 from './404.module.scss';

/**
 * NotFoundPage: Custom 404 page
 * @returns
 */
export default function NotFoundPage() {
  return (
    <Container className={`d-flex flex-column ${Styles404.mainContainer}`}>
      <Row className={Styles404.navbarRow}>
        <Col className={Styles404.navbarCol}>
          <div className={Styles404.navWrapper}>
            <NavBar pageVersion="/" siteTocArray={null} />
          </div>
        </Col>
      </Row>
      <Row className={`${Styles404.mainRow} border-left`}>
        <Col>
          <div className="container py-4 text-center">
            <h1>404: PAGE NOT FOUND</h1>
            <p>This page does not exist. Such empty...</p>
          </div>
        </Col>
      </Row>
      <Row className={Styles404.footerRow}>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
}
